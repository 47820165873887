import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { AuthService } from "../auth/auth.service";
import { ProjectService } from "../project/project.service";

@Injectable({
	providedIn: "root",
})
export class ImagesService {
	constructor(private projectSvc: ProjectService, private authSvc: AuthService, private http: HttpClient) {}

	getLogo(): string {
		if (this.projectSvc.projectConfig) {
			return environment.urlDomainImages + "/rest/image/" + this.projectSvc.projectConfig.entryPoint.resources + "/logo";
		}
	}

	getLogoHeader(): string {
		if (this.projectSvc.projectConfig) {
			return environment.urlDomainImages + "/rest/image/" + this.projectSvc.projectConfig.entryPoint.resources + "/logo/min";
		}
	}

	getAppLogo(): string {
		if (this.projectSvc.projectConfig) {
			return environment.urlDomainImages + "/rest/image/" + this.projectSvc.projectConfig.entryPoint.resources + "/app/logo";
		}
	}

	getAppLogoHeader(): string {
		if (this.projectSvc.projectConfig) {
			return environment.urlDomainImages + "/rest/image/" + this.projectSvc.projectConfig.entryPoint.resources + "/app/logo/header";
		}
	}

	setLogo(b64Logo: string, logoType: "logoWebApp" | "logoWebAppHeader" | "logoApp" | "logoAppHeader"): Observable<any> {
		if (this.projectSvc.projectConfig) {
			return new Observable<any>((observer) => {
				const accessToken = this.authSvc.getAccessToken();

				const options = {
					headers: new HttpHeaders({
						Authentication: "Bearer " + accessToken,
					}),
				};

				const byteCharacters = atob(b64Logo.split(",")[1]);
				const byteNumbers = new Array(byteCharacters.length);

				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i);
				}

				const byteArray = new Uint8Array(byteNumbers);
				const logoBlob = new Blob([byteArray], { type: "image/png" });

				const formData = new FormData();
				formData.append("file", logoBlob);

				let url = "";

				if (logoType === "logoWebApp") {
					url = environment.urlDomain + "/rest/image/" + this.projectSvc.projectConfig.entryPoint.resources + "/logo";
				} else if (logoType === "logoWebAppHeader") {
					url = environment.urlDomain + "/rest/image/" + this.projectSvc.projectConfig.entryPoint.resources + "/logo/min";
				} else if (logoType === "logoApp") {
					url = environment.urlDomain + "/rest/image/" + this.projectSvc.projectConfig.entryPoint.resources + "/app/logo";
				} else if (logoType === "logoAppHeader") {
					url = environment.urlDomain + "/rest/image/" + this.projectSvc.projectConfig.entryPoint.resources + "/app/logo/header";
				}

				this.http.post(url, formData, options).subscribe(
					(response: any) => {
						observer.next(response);
					},
					(err) => {
						observer.error(err);
					}
				);
			});
		} else {
			return new Observable<any>((observer) => {
				observer.error("No se ha configurado el proyecto");
			});
		}
	}

	getImageThumb(username: string): string {
		return environment.urlDomainImages + "/rest/image/" + this.projectSvc.getProjectId() + "/thumb/@" + username;
	}

	getImageThumbProject(username: string, project: number): string {
		return environment.urlDomainImages + "/rest/image/" + project + "/thumb/@" + username;
	}

	getImageThumbAdmin(username: string): string {
		const localP = String(this.projectSvc.getProyectoAdmin());

		return environment.urlDomainImages + "/rest/image/" + localP + "/thumb/@" + username;
	}

	getImagePerson(username: string, proyect: number = null): string {
		return environment.urlDomainImages + "/rest/image/" + this.projectSvc.getProjectId() + "/person/@" + username;
	}

	getImageGroup(): string {
		return environment.urlDomainImages + "/javax.faces.resource/static/img/" + this.projectSvc.projectConfig.entryPoint.groupImage;
	}

	getImageCompetency(competencyId): string {
		return environment.urlDomainImages + "/rest/image/" + this.projectSvc.getProjectId() + "/competency/" + competencyId;
	}

	getImageCompetencySize(competencyId, size): string {
		return environment.urlDomainImages + "/rest/image/" + this.projectSvc.getProjectId() + "/competency/" + competencyId + "/" + size;
	}

	getImageDefaultMission(): string {
		return environment.urlDomainImages + "/rest/image/competency/defaultMission";
	}

	getImageShopProduct(productId): string {
		return environment.urlDomainImages + "/rest/image/" + this.projectSvc.getProjectId() + "/product/" + productId;
	}

	getUrlImageCompetency(competency): string {
		return environment.urlDomainImages + "/rest/image/" + this.projectSvc.getProjectId() + "/competency/" + competency.id;
	}

	getUrlImageDefaultMission(): string {
		return environment.urlDomainImages + "/rest/image/competency/defaultMission";
	}

	convertImageToBase64(imageUrl: string): Promise<string> {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.src = imageUrl;
			img.crossOrigin = "Anonymous";

			img.onload = () => {
				const canvas = document.createElement("canvas");
				canvas.width = img.width;
				canvas.height = img.height;

				const ctx = canvas.getContext("2d");
				ctx.drawImage(img, 0, 0);

				resolve(canvas.toDataURL());
			};

			img.onerror = (err) => {
				reject(err);
			};
		});
	}
}
